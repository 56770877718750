exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadastro-2-tsx": () => import("./../../../src/pages/cadastro2.tsx" /* webpackChunkName: "component---src-pages-cadastro-2-tsx" */),
  "component---src-pages-cadastro-3-tsx": () => import("./../../../src/pages/cadastro3.tsx" /* webpackChunkName: "component---src-pages-cadastro-3-tsx" */),
  "component---src-pages-cadastro-4-tsx": () => import("./../../../src/pages/cadastro4.tsx" /* webpackChunkName: "component---src-pages-cadastro-4-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../../../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-codigo-tsx": () => import("./../../../src/pages/codigo.tsx" /* webpackChunkName: "component---src-pages-codigo-tsx" */),
  "component---src-pages-compras-tsx": () => import("./../../../src/pages/compras.tsx" /* webpackChunkName: "component---src-pages-compras-tsx" */),
  "component---src-pages-configuracoes-tsx": () => import("./../../../src/pages/configuracoes.tsx" /* webpackChunkName: "component---src-pages-configuracoes-tsx" */),
  "component---src-pages-conta-bancaria-tsx": () => import("./../../../src/pages/conta-bancaria.tsx" /* webpackChunkName: "component---src-pages-conta-bancaria-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-horario-tsx": () => import("./../../../src/pages/horario.tsx" /* webpackChunkName: "component---src-pages-horario-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nova-senha-[codigo]-index-tsx": () => import("./../../../src/pages/novaSenha/[codigo]/index.tsx" /* webpackChunkName: "component---src-pages-nova-senha-[codigo]-index-tsx" */),
  "component---src-pages-produtos-tsx": () => import("./../../../src/pages/produtos.tsx" /* webpackChunkName: "component---src-pages-produtos-tsx" */),
  "component---src-pages-recuperar-tsx": () => import("./../../../src/pages/recuperar.tsx" /* webpackChunkName: "component---src-pages-recuperar-tsx" */),
  "component---src-pages-relatorios-tsx": () => import("./../../../src/pages/relatorios.tsx" /* webpackChunkName: "component---src-pages-relatorios-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

