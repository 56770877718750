import React from "react";
import { CookiesProvider } from "react-cookie";
import { Global, css } from "@emotion/react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => {
  if (typeof window === undefined) return null;

  const { pageResources, pageContext, serverData, ...rest } = props;

  return (
    <CookiesProvider>
      <div {...rest}>
        <ToastContainer />
        {element}
        <Global
          styles={css`
            .chakra-avatar__img {
              margin-bottom: 0 !important;
            }
          `}
        />
      </div>
    </CookiesProvider>
  );
};

export default wrapPageElement;
